import { getUserData, getRoles } from '@/auth/utils';

function checkRole(to, from, next) {    
    const userData = getUserData();
    const { REVISER } = getRoles();
    
    if (userData.role_id === REVISER) next();
    else next({name: 'home'});    
}

export default [{
        path: '/reviser/forms',
        name: 'reviser-forms',
        component: () => import ('@/views/modules/reviser/forms/forms-list/FormsList.vue'),
        beforeEnter: checkRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Lista de formularios',
                active: true
            }]
        }
    },
    {
        path: '/reviser/form/:id/edit',
        name: 'reviser-form-edit',
        component: () => import ('@/views/modules/reviser/forms/forms-edit/FormsEdit.vue'),
        beforeEnter: checkRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Completar Formulario'
        }
    },   
]