import { getUserData, getRoles } from '@/auth/utils';

function checkRole(to, from, next) {    
    const userData = getUserData();
    const { ADMIN } = getRoles();
    
    if (userData.role_id === ADMIN) next();
    else next({name: 'home'});    
}

export default [{
    path: '/calendar',
    name: 'admin-calendar',
    component: () => import ('@/views/modules/calendars/Calendar.vue'),
    beforeEnter: checkRole,
    meta: {
        action: 'read',
        resource: 'ACL',
        pageTitle: 'Calendario de Evaluaciones',
        breadcrumb: [{
            text: 'Calendario',
            active: true
        }]
    }
},
]