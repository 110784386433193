import Vue from 'vue';
import VueRouter from 'vue-router';


// Libs
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';


// Routes
import home from './routes/home';
import login from './routes/authentication/login';
import account from './routes/account';
import revisers from './routes/admin/revisers';
import providers from './routes/admin/providers';
import calendars from './routes/admin/calendars';
import reporteria from './routes/admin/reporteria';
import miscellaneous from './routes/miscellaneous';
import formsAdmin from './routes/admin/forms';
import formsProvider from './routes/provider/forms';
import reportsProvider from './routes/provider/reports';
import formsReviser from './routes/reviser/forms';
import calendarsReviser from './routes/reviser/calendars';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        ...home,
        ...login,
        ...account,
        ...revisers,
        ...providers,
        ...calendars,
        ...reporteria,
        ...miscellaneous,
        ...formsAdmin,
        ...formsProvider,
        ...reportsProvider,
        ...formsReviser,
        ...calendarsReviser,
        {
            path: '*',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        }
    ]
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'login' });        
        if (isLoggedIn) return next({ name: 'home' });

        // If logged in => not authorized
        return next({ name: 'misc-not-authorized' });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;