import { getUserData, getRoles } from '@/auth/utils';

function checkRole(to, from, next) {    
    const userData = getUserData();
    const { PROVIDER } = getRoles();
    
    if (userData.role_id === PROVIDER) next();
    else next({name: 'home'});    
}

export default [{
		path: '/provider/reports',
		name: 'provider-reports',
		component: () => import ('@/views/modules/provider/reports/reports-list/ReportsList.vue'),
		beforeEnter: checkRole,
		meta: {
			action: 'read',
			resource: 'ACL',
			pageTitle: 'Estadísitcas & Reportes',
			breadcrumb: [{
				text: 'Comparación de Promedio',
				active: true
			}]
		}
	},
]