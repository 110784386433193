import { getUserData, getRoles } from '@/auth/utils';

function checkRole(to, from, next) {    
    const userData = getUserData();
    const { ADMIN } = getRoles();
    
    if (userData.role_id === ADMIN) next();
    else next({name: 'home'});    
}

export default [
    {
        path: '/revisers',
        name: 'revisers',
        component: () => import('@/views/modules/revisers/revisers-list/RevisersList.vue'),
        beforeEnter: checkRole,
        meta: {
            resource: 'ACL',
            pageTitle: 'Revisores',
            breadcrumb: [
                {
                    text: 'Lista de revisores',
                    active: true
                }
            ]
        }
    },
    {
        path: '/revisers/:id/edit',
        name: 'revisers-edit',
        component: () => import('@/views/modules/revisers/revisers-edit/RevisersEdit.vue'),
        beforeEnter: checkRole,
        meta: {
            pageTitle: 'Revisores',
            breadcrumb: [
                {
                    text: 'Editar revisor',
                    active: true
                }
            ]
        }
    }
]